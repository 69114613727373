//services page css - create one for each page and include to keep shit organised.

.services {

	&.gridify {
		@apply gap-4 grid-cols-1 {}
		// @screen lg
		// 	@apply gap-2 grid-cols-2
		// @screen xl
 } }		// 	@apply gap-2 grid-cols-2


.content-layout {
	@apply flex text-base mb-2 {}
	.title {
		width: 33.3333%;
		opacity: 0.5; }
	.content {
		width: 66.6666%; } }

.our-services {
	.services-categories {
		@apply flex justify-between mb-4 {}

		li {
			&.all {
			 opacity: 0.5; }
			&.selected {
				@apply underline {} }
			&:hover {
				@apply underline cursor-pointer {} } } }

	.services-list {
		@apply flex flex-col {}
		.content-layout {
			&.hide {
				@apply hidden {} } } } }
