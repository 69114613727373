nav {
	@apply fixed inset-0 bg-highlight z-50 flex justify-center items-center pointer-events-none opacity-0 {}
	background-image: linear-gradient(140deg, #ff1859, #0013ff);
	transition: all 0.3s ease;

	&.open {
		@apply pointer-events-auto opacity-100 {}
		transition: all 0.3s ease;

		ul {
			transform: scale(1); } }

	header {
		mix-blend-mode: normal;

		.logo {
			@apply text-light {} } }

	ul {
		@apply font-headings text-2xl leading-none px-1 text-center text-white {}
		@screen md {
			@apply text-2xl px-2 {} }
		@screen lg {
			@apply text-2xl text-center {} }
		@screen xl {
			@apply text-4xl {} }
		transition: all 0.3s ease;
		transform: scale(0.95);

		li {
			transform: skewY(-10deg);
			&.outlined {
				@apply text-white {}
				&:hover {
					@apply font-headings {}
					@apply text-white {} } } } } }

.nav-bars {
	@apply relative cursor-pointer block {}
	height: 15px;
	width: 40px;
	@screen md {
		@apply hidden {}
		height: 20px;
		width: 40px; }
	span {
		@apply absolute {}
		width: 100%;
		height: 1px;
		background: $dark;
		// inverse of what it should be, due to 'exclusion' mix-blend-mode
		@screen md {
			height: 2px; }

		&.top {
			top: 0; }
		&.mid {
			top: 7px;
			@screen md {
				top: 9px; } }
		&.bot {
			bottom: 0; } } }

.nav-close {
	@apply absolute cursor-pointer {}
	top: 50%;
	transform: translateY(-50%);
	right: 3px;
	width: 35px;
	height: 35px;
	@screen md {
		right: 0;
		width: 40px;
		height: 40px; }
	span {
		@apply absolute {}
		width: 100%;
		height: 1px;
		background: $light;
		@screen md {
			height: 2px; }

		&:first-child {
			top: 50%;
			transform: rotate(45deg); }
		&:last-child {
			top: 50%;
			transform: rotate(-45deg); } } }
