//home page css - create one for each page and include to keep shit organised.
#intro {
	@apply relative {}

	canvas {
		@apply absolute inset-0 {} }

	#bgvid {
		object-fit: cover;
		width: 100%;
		height: 100vh;
		position: absolute;
		pointer-events: none;
		top: 0;
		left: 0;
		opacity: 1;
		transition: opacity 0.3s ease;
		&.dim {
			opacity: 0.05 !important; } } }

.intro {
	@apply z-20 relative flex {}
	// @screen md
	// 	@apply justify-center

	h1 {
		@apply text-2xl font-headings leading-none uppercase {}
		transform: skewY(-10deg);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		will-change: transform;

		@screen md {
			@apply text-xl {} }
		@screen lg {
			@apply text-2xl {} }
		@screen xl {
			@apply text-4xl {} }

		a {
			@apply block {}
			transition: padding 0.3s ease;

			&:hover {
				@apply pl-2 {}
				-webkit-text-fill-color: transparent;
				background-clip: text;
				will-change: transform; } }

		.tag-2 {
 }			// transition: color 0.6s ease

		canvas {
			margin: 0 auto; }


		a:hover {
			background-image: linear-gradient(170deg, #ff1859, #0013ff); } } }

.intro-text {
	@apply text-lg {}
	@screen md {
		@apply text-xl {} } }

section,
.heading-group {
	@apply py-1 {}
	@screen md {
		@apply py-2 {} } }


.client-logos {
	@apply grid grid-cols-3 gap-2 {}
	@screen md {
		@apply grid grid-cols-4 gap-2 {} }
	@screen lg {
		@apply grid grid-cols-6 gap-2 {} } }

.partner-logos {
	@apply flex flex-wrap flex-1 justify-center {}

	img.partner-logo {
		@apply m-1 {}
		width: auto;
		height: 15px;
		&.bigcommerce-partner {
			height: 22px; }
		@screen md {
			@apply m-2 {}
			height: 45px;

			&.bigcommerce-partner {
				height: 55px; } } } }


.client-logo,
.partner-logo {
	opacity: 0.9; }
