#project {

	#page-header {
		@screen md {
			min-height: 100vh; }

		// overrides for default BG colour & transition - added a delay to project to align with 'website browser build'
		@apply bg-light {}

		/* heading & collaborators */
		.project-heading,
		.collaborators {
			opacity: 0;
			transition: opacity 0.5s ease 1.75s; }

		/* hero image */
		.hero-image {
			@apply relative {}
			max-width: 1200px;
			margin: 0 auto;

			.edge {
				@apply absolute bg-black {}
				transition: transform 0.5s $easeInOutQuart, opacity 0.5s $easeInOutQuart;
				opacity: 0;
				&.t,&.b {
					height: 2px; }
				&.l,&.r {
					width: 2px; }
				&.t {
					@apply inset-x-0 top-0 {}
					transform: scaleX(0); }
				&.r {
					@apply inset-y-0 right-0 {}
					transform: scaleY(0); }
				&.b {
					@apply inset-x-0 bottom-0 {}
					transform: scaleX(0); }
				&.l {
					@apply inset-y-0 left-0 {}
					transform: scaleY(0); } }

			.image-header {
				@apply flex items-center relative {}
				.edge.inner {
					@apply inset-x-0 top-0 {}
					transition: transform 0.4s $easeInOutQuart 0.3s, opacity 0.1s $easeInOutQuart 0.3s;
					height: 2px; }
				.but {
					@apply bg-current rounded-full mx-025 {}
					width: 10px;
					height: 10px;
					opacity: 0;

					&.but1 {
						transition: opacity 0.4s ease-out 0.6s; }
					&.but2 {
						transition: opacity 0.4s ease-out 0.65s; }
					&.but3 {
						transition: opacity 0.4s ease-out 0.7s; } } }

			.image-frame {
				@apply relative {}
				.terminal-container {
					@apply absolute inset-0 z-10 {}
					.terminal {
						@apply w-full h-full {}
						transition: transform 0.25s ease-in 1.2s, opacity 0.25s ease-in 1.2s;
						--color: $dark;
						--background: $light;
						--size: 1.25;
						transform: translateY(0rem);
						opacity: 1;
						.cmd span.inverted {
							@apply bg-dark text-white {} }

						.terminal-output {
							> div {
								margin-bottom: 0.2rem;
								&:first-child {
									margin-bottom: 0.5rem; } }

							span.status {
								@apply font-bold {}
								color: green; } }

						&.complete {
							transform: translateY(0);
							opacity: 0; } } }

				img {
					@apply relative z-20 {}
					transition: opacity 0.5s ease-in 1.75s;
					opacity: 0; } } }

		/* ".build" class applied to "#page-header" to control header animations */
		&.build {

			.project-heading,
			.collaborators {
				opacity: 1; }

			.hero-image {
				.edge.t,.edge.r,.edge.b,.edge.l {
					opacity: 1; }

				.edge {
					&.t {
						@apply inset-x-0 top-0 {}
						transform: scaleX(1); }
					&.r {
						@apply inset-y-0 right-0 {}
						transform: scaleY(1); }
					&.b {
						@apply inset-x-0 bottom-0 {}
						transform: scaleX(1); }
					&.l {
						@apply inset-y-0 left-0 {}
						transform: scaleY(1); } }

				.image-header {
					.edge.inner {
						opacity: 1;
						transform: translateY(1.45rem); }
					.but {
						opacity: 1; } }

				.image-frame {
					img {
						opacity: 1; } } }

			// &.pip
			// 	@apply fixed
			// 	bottom: 2rem
 } }			// 	right: 2rem

	section {
		@apply py-15 block {}
		@screen md {
			@apply py-6 {} } }

	.excerpt {
 }		// stuff

	.project-description {
		@apply text-base {} } }
