.outlined {
	@apply font-outheadings text-dark {} }

.heading-group {
	@apply text-lg uppercase leading-tight font-headings mb-05 {}
	@screen md {
		@apply text-xl {} }

	&.large {
		@screen md {
			@apply text-2xl {} }
		@screen lg {
			@apply text-4xl {} } } }

.prose {
	p {
		@apply my-05 {} }

	.container > ol > li:first-child p {
		@apply mt-0 {} }

	&.cols-2 {
		columns: 2;
		column-gap: 2rem; } }


.terms-and-conditions {
	ol, ul {
		@apply ml-1 {} }
	ol {
		@apply list-decimal {} }

	.container > ol > li > p:first-child {
		@apply underline {} }

	ul {
		@apply list-disc {}
		list-style-type: disc; } }
