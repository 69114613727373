header {
	@apply fixed w-full p-15 leading-none text-light flex justify-between z-20 top-0 {}
	// mix-blend-mode: exclusion

	@screen md {
		@apply p-2 {} }

	.logo {
		// inverse of what it should be, due to 'exclusion' mix-blend-mode
		color: $dark;
		svg {
			height: auto;
			max-width: 100px;
			@screen md {
				max-width: 170px; } } }

	.right {
		@apply flex relative items-center {}

		ul {
			@apply items-center hidden {}
			@screen md {
				@apply flex {} }
			li {
				@apply inline-block .text-sm mx-05 relative text-dark {}
				&:last-child {
					@apply .mr-0 {} }
				&:after {
					@apply bg-current absolute {}
					left: 0;
					bottom: -5px;
					content: "";
					height: 2px;
					width: 100%;
					transition: transform 0.3s $easeInOutQuart;
					transform: scaleX(0); }
				&.active,
				&:hover {
					&:after {
						transform: scaleX(1); } } } } } }

