//base html stuff
html,
body {
	@apply bg-light text-navy font-body h-full min-h-full antialiased {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;
	font-size: 90%;
	@screen sm {
		font-size: 130%; }

	@screen lg {
		font-size: 140%; }

	&.nav-open {
		overflow-y: hidden; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	display: block;
	width: 100%; }

#preload-bar {
	@apply bg-light fixed w-full top-0 left-0 right-0 z-50 {}
	height: 3px;

	svg {
		height: 3px; }

	path {
		@apply stroke-current text-dark {} } }

#preload-counter {
	@apply fixed z-30 top-0 right-0 mt-1 mr-1 pointer-events-none {}
	display: none; }

#preload-overlay {
	@apply bg-light fixed w-full h-full top-0 left-0 z-50 {} }

#page-loader {
	// @apply fixed h-screen bg-dark z-20
	// right: 0
	// width: 0
	display: none; }

#barba-wrapper {
	@apply relative z-10 bg-light min-h-screen {}
	transition: background-color 0.5s ease; }

.barba-container {
	@apply w-full {} }


form#supportForm {
	@apply mx-auto {}
	max-width: 800px; }
