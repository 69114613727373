.svg-logo {
	fill: blue; }

.container {
	max-width: none;
	&.sm {
		max-width: 900px; }
	&.md {
		max-width: 1200px; }
	&.lg {
		max-width: 1600px; } }

section {
	@apply flex items-center px-15 {}
	@screen md {
		@apply px-2 {} }
	.container:not(.visible) {
		transition: opacity 1s ease;
		opacity: 0;
 }		// filter: blur(10px)

	&:last-child {
		@apply pb-2 {}
		@screen md {
			@apply .pb-6 {} } }

	&.screen {
		@apply min-h-screen {} }

	&[data-emergence="visible"] {
		.container {
			opacity: 1;
 } } }			// filter: blur(0px)

#page-header {
	transition: background-color 1s ease;
	@apply px-15 pb-15 pt-6 {}
	@screen md {
		@apply gap-2 px-2 pb-4 {} }

	.hero-image {
		@apply mt-15 {}
		@screen md {
			@apply pt-0 {} } } }

.content-intro {
	max-width: 1000px; }

.two-col {
	@apply flex justify-between {}

	.col {
		width: 45%; } }

.copy {
	p {
		@apply mb-1 {} } }

.list {
	p {
		@apply mb-05 {} } }

.btn {
	@apply p-05 px-1 text-base bg-light text-dark border-dark border-2 {}
	transition: all 0.5s ease;
	span {
		@apply pr-1 {} }
	&:hover {
		@apply bg-dark text-light {} } }

.theme-switcher {
	@apply text-dark flex items-center pr-1 cursor-pointer {}
	@screen md {
		@apply pr-2 {} }

	i {
		transition: transform 0.3s ease, opacity 0.3s ease;
		will-change: transform;
		&.dark {
			transform: rotate(180deg); } } }

.theme-switcher-label {
	@apply text-sm {}
	@screen md {
		@apply text-xs {} } }

.gridify {
	@apply grid grid-cols-1 {}

	@screen sm {
		@apply gap-1 grid-cols-2 {} }

	@screen md {
		@apply gap-2 grid-cols-2 {} }

	@screen lg {
		@apply gap-2 grid-cols-3 {} }

	@screen xl {
		@apply gap-2 grid-cols-4 {} } }

.md {
	.gridify {
		@screen xl {
			@apply gap-2 grid-cols-3 {} } } }

#map {
	@apply .absolute .inset-0 .z-10 {}
	opacity: 0;
	transition: opacity 0.6s ease;
	&.loaded {
		transition: opacity 3s ease;
		opacity: 0.25; } }

.marker {
	background-size: cover;
	width: 30px;
	height: 30px; }


.index-grid {
	@apply .text-base {}
	.row {
		@apply flex border-b py-05 {}
		&.row-header span {
			opacity: 50%; } }
	.col {
		@apply .leading-tight {}
		width: 40%; } }


.projects {
	@screen md {
		@apply grid grid-cols-2 gap-2 {} }
	@screen xl {
		@apply grid grid-cols-3 gap-2 {} }
	@screen xxl {
		@apply grid grid-cols-4 gap-2 {} }

	.project {
		@apply relative my-15 {}
		overflow: hidden;
		background: rgba(255,255,255,0.05);
		transition: background 0.3s ease;

		&:hover {
 }			// @apply bg-light text-highlight

		@screen md {
			@apply my-0 {} }

		a.link {
			@apply underline {}
			display: block;
			transition: opacity 0.3s ease;

			&::after {
				content: '';
				display: none;
				position: absolute;
				width: 0;
				height: 0;
				top: 0;
				left: 0; }

			&:hover::after {
				display: block; } }

		img {
			transition: opacity 1s ease;
			opacity: 0; }

		&[data-emergence="visible"] img {
			opacity: 1; }

		.title,
		.collaborators {
			@apply text-base {}
			margin: 0;
			opacity: 0;
			transition: opacity 0.3s ease, filter 0.5s linear;
			position: absolute;
			z-index: 1;
 }			// filter: blur(3px)

		.title {
			@apply font-headings text-base leading-tight {}
			top: 1.5rem;
			left: 1.5rem;
			right: 1.5rem;
			@screen xxxl {
				@apply text-lg {} } }

		.collaborators {
			bottom: 1.5rem;
			right: 1.5rem;
			left: 1.5rem; }

		&:hover {
			.link {
				opacity: 0.05; }
			.title,
			.collaborators {
				opacity: 1;
 } } } }				// filter: blur(0px)

// .z-layer:not(:first-child)
// 	filter: brightness(0.3)

// .z-text
// 	transform: rotateX(-5deg) rotateY(5deg)
// 	transition: transform 1s

// .intro.desktop h1:hover .z-text
// 	transform: rotateX(5deg) rotateY(-5deg) skewY(0)

.parsley-errors-list {
	@apply hidden {} }
