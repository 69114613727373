html#quote-template,
html#quote-template body,
html#quote-template #preload-overlay {
	@apply bg-white {} }

#quote {
	@apply bg-white text-dark {}

	.quote-wrapper {
		@apply mx-auto bg-white p-2 {}
		max-width: 1200px; }

	.sub-header {
		@apply py-3 leading-tight {}
		.rockethouse-title {
			@apply text-xl {} }
		.quote-client {
			@apply text-sm {} }
		.quote-title {
			@apply text-lg {} } }

	.item {
		@apply mb-2 {}
		&:first-of-type {
			@apply mt-0 {} }
		&:last-of-type {
			@apply mb-0 {} }

		.item-title,
		.item-fee {
			@apply text-lg {} } }

	.item-title-wrapper {
		@apply flex justify-between border-b-2 border-dark py-025 {} }

	.item-detail-wrapper {
		p {
			@apply my-1 {} }
		ul {
			// @apply .my-1
			li {
				p {
					@apply my-025 pl-1 relative {}
					&:before {
						@apply absolute left-0 {}
						content: "—"; } } } } }

	.price-grid-wrapper {
		&.with-border {
			@apply border-t-2 {} }
		.line-item {
			@apply flex justify-between text-base border-b-2 border-dark {}

			.line-item-title,
			.line-item-fee {
				@apply py-05 text-base {} } } }

	.quote-totals {
		@apply mt-3 border-t-2 border-dark text-lg {}
		.total-line {
			@apply flex justify-between border-b-2 border-dark py-025 {} } }

	.quote-footer {
		@apply text-center text-base {} }

	.quote-acceptance {
		max-width: 1200px; }

	input {
		@apply leading-snug {}
		background: transparent;
		&:disabled {
			@apply w-auto {}
			background: transparent; } }

	.btn {
		@apply bg-white text-dark px-1 py-05 cursor-pointer border-dark {}
		transition: all 0.2s ease;
		&:hover {
			@apply bg-dark text-white {} } } }

@media print {
	@page {
		size: A4 portrait;
		margin: 1cm; }
	#quote {
		.quote-wrapper {
			@apply p-0 {} }
		.item {
			page-break-inside: avoid;
			.item-title-wrapper {
				@apply border-b {} }
			.price-grid-wrapper {
				&.with-border {
					@apply border-t {} }
				.line-item {
					@apply border-b {} } } }
		.page-break {
			page-break-after: always; }

		.quote-totals {
			@apply border-t {}
			.total-line {
				@apply border-b {} } }

		.quote-footer {
			@apply fixed bottom-0 left-0 right-0 w-full {} } }

	#terms-and-conditions {
		line-height: 1.1; } }

input.parsley-error {
	@apply border-red {} }
