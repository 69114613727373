// dark mode 'dm' applied to html and all descendents
@mixin darkMode() {
	@apply bg-dark text-light {}
	body {
		@apply bg-dark text-light {}

		#preload-bar {
			@apply bg-dark {}

			path {
				@apply stroke-current text-light opacity-25 {} } }

		#preload-overlay {
			@apply bg-black {} }

		#page-loader {
			@apply bg-light {} }

		#barba-wrapper {
			@apply bg-dark {} }

		/* Globals */
		.btn {
			@apply border-light text-light bg-dark {}
			&:hover {
				@apply bg-light text-dark {} } }

		/* Type */
		h1, h2, h3, h4 {
 }			// @apply .font-light

		.outlined {
			@apply font-outheadings text-light {} }

		/* Header */
		header {
			.logo {
				@apply text-light {}
				svg {
					fill: $light; } }
			.right {
				ul li {
					@apply text-light {} } } }

		#page-header {
 }			// background-color: rgba($light, 0.05)

		/* Home */
		.intro {
			h1 {
				background-image: linear-gradient(170deg, #18ffdf, #0013ff);
				a {
					&:hover {
						background-image: linear-gradient(170deg, #d2fff9, #99a1ff); } } } }

		.projects {
			.project {
				background: rgba(255,255,255,0.05);

				&:hover {
					@apply bg-dark {} } } }

		/* Nav */
		nav {
			@apply bg-highlight {}
			ul {
				li.outlined {
					@apply .text-white {}
					&:hover {
						@apply .text-white {} } } } }

		.nav-bars {
			span {
				background: $light; } }

		.nav-close {
			span {
				background: $light; } }

		footer {
			.footer-two {
				@apply bg-dark text-light {} } }

		.client-logos {
			img {
				filter: invert(1); } }

		img.partner-logo {
				filter: invert(1); }

		.theme-switcher {
			@apply text-light {} }


		/* PROJECTS PAGE: */
		#project {
			#page-header {
				@apply bg-black {}
				.hero-image {
					.edge {
						@apply bg-light {} }
					.image-frame {
						.terminal-container {
							.terminal {
								--color: white;
								--background: $dark;
								.cmd span.inverted {
									@apply bg-white text-dark {} } } } } } } }

		/* CONTACT PAGE  */
		#contact {
			@apply bg-dark {}

			#map {
				mix-blend-mode: difference; } } } }

// Dark theme conditional imports
html:not(.lm) {
	@media (prefers-color-scheme: dark) {
		@include darkMode; } }

html.dm {
 	@include darkMode; }
