footer {
	.container {
		@apply py-2 px-2 {}
		@screen md {
			@apply py-4 px-2 {} } }

	.footer-one {
		@apply py-2 {}
		@screen md {
			@apply py-4 {} } }

	.footer-two {
		@apply .bg-light .text-dark {}
		transition: all 0.5s ease; }

	.sub-footer-wrapper {
		@apply flex justify-between flex-col {}
		@screen md {
			@apply flex-row {} } }

	.social-footer {
		@apply flex-1 flex justify-between text-base items-end flex-wrap mt-2 {}
		@screen md {
			@apply justify-end mt-0 {} }

		a {
			@apply pr-1 flex {}
			@screen md {
				@apply px-05 {} } } } }
